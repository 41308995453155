export default {
  title: '轮播展示组件',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/packages/roll-exhibition/RollExhibition.vue',
  version: '0.0.1',
  type: 'container',
  componentName: 'ofa-roll-exhibition',
  visible: true,
  form: {
    dataSource: {
      label: '数据项',
      type: 'array-object',
      options: {
        image: {
          label: '图片',
          type: 'text'
        },
        id: {
          label: 'id',
          type: 'text'
        }
      }
      // extraProps: { max:10 //设置输入项的上限 }
    },
    hasPagination: {
      label: '显示指标',
      type: 'switch'
    },
    hasNavigation: {
      label: '显示左右切换',
      type: 'switch'
    },
    slidesPerView: {
      type: 'text',
      label: '显示个数'
    },
    spaceBetween: {
      type: 'text',
      label: '间隔'
    },
    compatibleProps: {
      label: '兼容属性',
      children: {
        id: {
          label: 'ID',
          type: 'text'
        },
        image: {
          label: '图片',
          type: 'text'
        }
      }
    },
    autoplay: {
      label: '自动滚动',
      type: 'switch'
    },
    autoDelay: {
      label: '播放时间间隔(秒)',
      type: 'number'
    },
    loop: {
      label: '是否循环播放',
      type: 'switch'
    },
    effects: {
      label: '切换效果',
      type: 'select',
      options: [
        { label: '位移切换', value: 'slide' },
        { label: '淡入', value: 'fade' },
        { label: '方块', value: 'cube' },
        { label: '3d流', value: 'coverflow' },
        { label: '3d翻转', value: 'flip' }
      ]
    }
  },
  props: {
    dataSource: [
      {
        image:
          'https://img1.baidu.com/it/u=2569348294,2008255036&fm=26&fmt=auto&gp=0.jpg',
        id: 1
      },
      {
        image:
          'https://img1.baidu.com/it/u=1020416772,1164026032&fm=26&fmt=auto&gp=0.jpg',
        id: 2
      },
      {
        image:
          'https://img0.baidu.com/it/u=3870460329,101682648&fm=26&fmt=auto&gp=0.jpg',
        id: 3
      },
      {
        image:
          'https://img1.baidu.com/it/u=4287902809,3128310250&fm=26&fmt=auto&gp=0.jpg',
        id: 4
      }
    ],
    hasPagination: true,
    hasNavigation: false,
    slidesPerView: '',
    spaceBetween: '',
    compatibleProps: {
      id: 'score',
      image: 'value'
    },
    autoplay: true,
    autoDelay: 3,
    loop: false,
    effects: 'slide'
  },
  emits: [{ label: '点击图片', value: 'selectImage' }]
}
