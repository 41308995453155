<template>
  <a-menu
    v-model:selectedKeys="selectedKeys"
    v-model:openKeys="openKeys"
    v-bind="$attrs"
    :style="getCss"
    :data-source="detail"
  >
    <template v-for="(item, index) in detail" :key="index">
      <a-sub-menu
        v-if="
          item[compatibleProps.children] &&
          item[compatibleProps.children].length > 0
        "
        :key="item[compatibleProps.id]"
        :title="item[compatibleProps.title]"
        @titleClick="clickItem(item, index)"
      >
        <a-menu-item
          v-for="inner in item[compatibleProps.children]"
          :key="inner[compatibleProps.id]"
          :value="currentMenuKey"
          @click="clickItem(item, index, inner)"
          >{{ inner[compatibleProps.title] }}</a-menu-item
        >
      </a-sub-menu>
      <a-menu-item
        v-else
        :key="item[compatibleProps.id]"
        :value="currentMenuKey"
        @click="clickItem(item, index)"
        >{{ item[compatibleProps.title]
        }}<span v-if="hasItemArrow" class="icon-arrow"></span
      ></a-menu-item>
    </template>
  </a-menu>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Menu as AMenu } from 'ant-design-vue'
import { reactive, toRefs, computed, watch } from 'vue'
const AMenuItem = AMenu.Item
const ASubMenu = AMenu.SubMenu

export default {
  name: `${prefix}menu`,
  components: {
    AMenu,
    AMenuItem,
    ASubMenu
  },
  props: {
    dataSource: {
      type: [String, Array],
      default: () => []
    },
    hasItemArrow: {
      type: Boolean,
      default: false
    },
    activeColor: {
      type: String,
      default: ''
    },
    // 兼容属性
    compatibleProps: {
      type: Object,
      default: () => {
        return {
          title: 'title',
          id: 'id',
          children: 'children'
        }
      }
    },
    currentMenuKey: String, //记录点击的子项标识,
    currentopenKey: String
  },
  emits: ['clickItem', 'update:currentMenuKey', 'update:currentopenKey'],
  setup(props, { emit, attrs }) {
    let state = reactive({
      selectedKeys: ['0'],
      openKeys: ['0']
    })

    watch(
      () => props.currentMenuKey,
      (newV) => {
        if (!attrs.multiple) state.selectedKeys = []
        newV && state.selectedKeys.push(newV)
      },
      {
        immediate: true
      }
    )
    watch(
      () => props.currentopenKey,
      (newV) => {
        if (!attrs.multiple) state.openKeys = []
        newV && state.openKeys.push(newV)
      },
      {
        immediate: true
      }
    )

    const detail = computed(() => {
      if (typeof props.dataSource == 'string') {
        return isJson(props.dataSource)
      } else if (Array.isArray(props.dataSource)) {
        return props.dataSource
      }
      return []
    })

    // 判断是不是json格式
    function isJson(str) {
      try {
        return JSON.parse(str)
      } catch (e) {
        console.log('error：' + str + '!!!' + e)
        return false
      }
    }

    //更改高亮颜色
    const getCss = computed(() => {
      return {
        '--activeColor': props.activeColor
      }
    })

    const clickItem = (val, index, inner) => {
      let openKey = inner?.id || val.id
      emit('update:currentMenuKey', openKey)
      emit('update:currentopenKey', val[props.compatibleProps.id])
      emit('clickItem', { value: val }, inner)
    }

    return {
      ...toRefs(state),
      detail,
      getCss,
      clickItem
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-menu-horizontal {
  .ant-menu-item {
    .icon-arrow {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: rotate(45deg);
      width: 8px;
      height: 8px;
      border: 1px solid #0077ff;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }
}
:deep(.ant-menu-item:hover),
:deep(.ant-menu-item:active),
:deep(.ant-menu-item-selected),
:deep(.ant-menu-submenu-title:hover),
:deep(.ant-menu-submenu:hover),
:deep(.ant-menu-submenu-selected),
:deep(.ant-menu-submenu-open) {
  //修改nav的高亮颜色。无法修改二级nav的hover颜色
  //若要修改二级nav颜色需要在编辑器代码以：.ofa-xxx .ant-menu-item-active, .ant-menu-item:hover 的方式修改
  color: var(--activeColor);
  border-color: var(--activeColor);
}
</style>
