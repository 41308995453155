import Input from './Input.vue'
import Group from './Group.vue'
import Search from './Search.vue'
import Password from './Password.vue'
import TextArea from './TextArea.vue'

Input.Group = Group
Input.Search = Search
Input.Password = Password
Input.TextArea = TextArea

/* istanbul ignore next */
Input.install = function (Vue) {
  Vue.component(Input.name, Input)
  Vue.component(Group.name, Input.Group)
  Vue.component(Search.name, Input.Search)
  Vue.component(Password.name, Input.Password)
  Vue.component(TextArea.name, Input.TextArea)
}

export default Input
