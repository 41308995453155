<template>
  <a-menu-item v-bind="$attrs">
    <slot></slot>
  </a-menu-item>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Menu as AMenu } from 'ant-design-vue'
const AMenuItem = AMenu.Item

export default {
  name: `${prefix}menu-item`,
  components: {
    AMenuItem
  }
}
</script>
