<template>
  <div
    v-infinite-scroll="[handleInfiniteOnLoad, scrollOptions]"
    class="list-module"
  >
    <a-list v-bind="$attrs" :data-source="detail" :locale="locale">
      <template #renderItem="{ item, index }">
        <a-list-item>
          <slot :data="item" :currentIndex="index"></slot>
          <!-- <slot name="listitem" :data="item"> </slot> -->
        </a-list-item>
      </template>
      <template #loadMore>
        <Loading :loading="loadMore">
          <div class="load-more"></div>
        </Loading>
        <slot v-if="isError" name="error">
          <p class="load-error" @click="request()"> 请求错误, 请点击重试 </p>
        </slot>
        <slot v-if="finished && detail.length" name="finished">
          <p class="load-finished">没有更多了</p>
        </slot>
      </template>
    </a-list>
    <div v-if="paginationVisible">
      <ofa-pagination
        v-model:pageSize="paginationPageSize"
        v-model:current="paginationCurrent"
        v-bind="defaultPagination"
      >
      </ofa-pagination>
    </div>
  </div>
</template>

<script lang="jsx">
import { prefix } from '@business/utils/index.js'
import { List as AList } from 'ant-design-vue'
import Pagination from '../pagination'
import { computed, reactive, toRefs, watch } from 'vue'
// eslint-disable-next-line no-unused-vars
import Empty from './component/Empty.vue'
import Loading from './component/Loading.vue'
import InfiniteScroll from './InfiniteScroll'
const AListItem = AList.Item

export default {
  name: `${prefix}list`,
  components: {
    AList,
    AListItem,
    Loading,
    [Pagination.name]: Pagination
  },
  directives: { InfiniteScroll },
  props: {
    dataSource: {
      type: [String, Array],
      default: () => []
    },
    paginationVisible: {
      type: [Boolean, String, Number],
      default: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    current: {
      type: Number,
      default: 1
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          disabled: false,
          defaultPageSize: 10,
          hideOnSinglePage: false
        }
      }
    },
    maxSize: {
      type: Number,
      default: 0
    },
    useEmpty: {
      type: Boolean,
      default: false
    },
    emptyMes: {
      type: String
    },
    emptyImg: {
      type: String
    },
    // 是否有下拉刷新功能
    loadMoreContent: {
      type: Boolean,
      default: false
    },
    scrollOption: {
      type: Object,
      default: () => {
        return {
          disabled: false,
          distance: 200 // 滑动触发距离
        }
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['request'],
  setup(props, { emit }) {
    const data = reactive({
      loadMore: false,
      lock: false,
      page: 1,
      isError: false,
      finished: false,
      loading: false,
      locale: {
        emptyText: <div></div>
      }
    })

    const scrollOptions = computed(() => {
      return props.scrollOption
    })

    const detail = computed(() => {
      if (typeof props.dataSource == 'string') {
        return props.maxSize > 0
          ? isJson(props.dataSource).slice(0, props.maxSize)
          : isJson(props.dataSource)
      } else if (Array.isArray(props.dataSource)) {
        return props.maxSize > 0
          ? props.dataSource.slice(0, props.maxSize)
          : props.dataSource
      }
      return []
    })

    const paginationPageSize = computed(() => {
      return props.pageSize
    })

    const paginationCurrent = computed(() => {
      return props.current
    })

    const defaultPagination = computed(() => {
      return props.pagination
    })

    // 判断页数有没有超过总页码, 需要在编辑器双向绑定pagesize去算是否还能加载，request之后触发
    watch(
      () => props.pageSize,
      (newValue) => {
        if (data.page >= newValue) {
          scrollOptions.value.disabled = true
          data.finished = true
        } else {
          data.page++
          scrollOptions.value.disabled = false
          data.finished = false
        }
      }
    )

    watch(
      () => props.isLoading,
      (val) => {
        data.loadMore = val
      }
    )

    watch(
      () => props.dataSource,
      () => {
        if (props.useEmpty) {
          data.locale = {
            emptyText: (
              <Empty
                class="table-empty"
                hasDefaultImg
                emptyImg={props.emptyImg}
                msg={props.emptyMes}
              ></Empty>
            )
          }
        } else {
          data.locale = {
            emptyText: <div></div>
          }
        }
      }
    )

    // 下拉到底触发加载数据
    const handleInfiniteOnLoad = async () => {
      if (!props.loadMoreContent) return
      if (scrollOptions.value.disabled || data.lock) return
      request()
    }

    const request = async () => {
      if (data.isError) {
        data.loading = true
      }
      if (data.page !== 1) {
        data.loadMore = true
      }
      data.lock = true
      try {
        console.log('上拉加载更多~触发了', detail.value)

        // 需要在此方法中更新current的页码触发watch中的计算
        // 与请求后端数据更新datasource拼接旧数组
        emit('request')
      } catch (e) {
        data.isError = true
        scrollOptions.value.disabled = true
        console.log(e)
      }

      data.lock = false
      data.loadMore = false
      data.loading = false
    }

    function isJson(str) {
      try {
        return JSON.parse(str)
      } catch (e) {
        console.log('error：' + str + '!!!' + e)
        return false
      }
    }

    return {
      ...toRefs(data),
      scrollOptions,
      detail,
      request,
      handleInfiniteOnLoad,
      paginationPageSize,
      paginationCurrent,
      defaultPagination
    }
  }
}
</script>
<style scoped lang="scss">
:deep(.list-module) {
  .ant-list {
    cursor: pointer;
  }
}
</style>
