export default {
  title: '输入框',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/Input.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-input',
  visible: true,
  form: {
    size: {
      label: '按钮大小',
      type: 'radio',
      options: [
        {
          label: 'small',
          value: 'small'
        },
        {
          label: 'default',
          value: 'default'
        },
        {
          label: 'large',
          value: 'large'
        }
      ]
    },
    maxLength: {
      label: '最大长度',
      type: 'text'
    },
    allowClear: {
      label: '是否显示清除图标',
      type: 'switch'
    },
    placeholder: {
      label: '占位文字',
      type: 'text'
    }
  },
  props: {
    size: 'default',
    maxLength: '',
    allowClear: false,
    inputValue: '',
    placeholder: '请输入'
  },
  model: {
    inputValue: {
      label: '输入框值',
      value: 'inputValue'
    }
  }
}
