<template>
  <a-input-text-area v-bind="$attrs"> </a-input-text-area>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Input } from 'ant-design-vue'
const AInputTextArea = Input.TextArea

export default {
  name: `${prefix}textarea`,
  components: {
    AInputTextArea
  }
}
</script>
