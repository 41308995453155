import Select from './Select.vue'
import Option from './Option.vue'
import OptGroup from './OptGroup.vue'

Select.Option = Option
Select.OptGroup = OptGroup

/* istanbul ignore next */
Select.install = function (Vue) {
  Vue.component(Select.name, Select)
  Vue.component(Option.name, Option)
  Vue.component(OptGroup.name, OptGroup)
}

export default Select
