<template>
  <div class="loading" :class="{ 'loading-disabled': loading }">
    <div v-if="loading" class="loading-mark">
      <div class="loading-inner">
        <div class="loading-dot">
          <span v-for="num in 4" :key="num" class="loading-dot-item"></span>
        </div>
        <p v-if="loadingText" class="loading-text">
          <span>{{ loadingText }}</span>
          <span v-for="num in 3" :key="num" class="loading-text-dot"></span>
        </p>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    loadingText: {
      type: String,
      default: '加载中'
    }
  }
}
</script>

<style lang="scss" scoped>
$fade-duration: 0.5s;
$jump-duration: 1.2s;

.loading {
  position: relative;
}

.loading-mark {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 96;
}

.loading-disabled {
  pointer-events: none;
  & > * {
    pointer-events: none;
  }
}

.loading-inner {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.loading-dot {
  &-item {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 12px;
    animation: jump $jump-duration infinite linear;
    &:last-child {
      margin-right: 0;
    }
  }
}

@each $color, $index, $duration in (#446bff, 1, 0), (#f96263, 2, 0.2),
  (#3bd1e0, 3, 0.4), (#fd81a4, 4, 0.6)
{
  .loading-dot .loading-dot-item:nth-child(#{$index}) {
    background-color: $color;
    animation-delay: #{$duration}s;
  }
}

.loading-text {
  margin: 0;
  margin-top: 15px;
  font-size: 16px;
  color: #364064;
}
.loading-text-dot {
  vertical-align: middle;
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-left: 3px;
  background-color: currentColor;
  animation: dot $fade-duration infinite linear;
}

.loading-text .loading-text-dot:nth-of-type(3) {
  animation-delay: 0.1s;
}
.loading-text .loading-text-dot:nth-of-type(4) {
  animation-delay: 0.2s;
}

@keyframes dot {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
//.loading-text-dot {
//  display: inline-block;
//  width: 10px;
//  margin-left: 2px;
//  min-height: 2px;
//  padding-left: 2px;
//  padding-right: 2px;
//  border-left: 2px solid currentColor;
//  border-right: 2px solid currentColor;
//  background-color: currentColor;
//  background-clip: content-box;
//  box-sizing: border-box;
//  animation: dot 1.5s infinite step-start both;
//}
//@keyframes dot {
//  25% {
//    border-color: transparent;
//    background-color: transparent;
//  }
//  50% {
//    border-right-color: transparent;
//    background-color: transparent;
//  }
//  75% {
//    border-right-color: transparent;
//  }
//}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
