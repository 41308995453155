<template>
  <a-carousel v-bind="$attrs" :effect="_effects" :autoplay="_autoplay">
    <div v-for="(item, index) in detail" :key="index" class="item">
      <img :src="item[compatibleProps.image]" :height="imgHeight" />
      <div class="title">
        <p>{{ item[compatibleProps.title] }}</p>
      </div>
    </div>
  </a-carousel>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Carousel as ACarousel } from 'ant-design-vue'
import { reactive, toRefs, watch, computed } from 'vue'

export default {
  name: `${prefix}ls-carousel`,
  components: {
    ACarousel
  },
  props: {
    dataSource: {
      type: [String, Array],
      default: () => []
    },
    imgHeight: {
      type: Number,
      default: 255
    },
    showImg: {
      type: Boolean,
      default: true
    },
    // 兼容属性
    compatibleProps: {
      type: Object,
      default: () => {
        return {
          title: 'title',
          image: 'image'
        }
      }
    },
    effects: {
      type: String,
      default: 'scrollx'
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    let state = reactive({
      _effects: props.effects,
      _autoplay: props.autoplay
    })

    //更改切换效果
    watch(
      () => props.effects,
      () => {
        state._effects = props.effects
      }
    )
    //更改自动播放
    watch(
      () => props.autoplay,
      () => {
        state._autoplay = props.autoplay
      }
    )

    const detail = computed(() => {
      if (typeof props.dataSource == 'string') {
        return isJson(props.dataSource)
      } else if (Array.isArray(props.dataSource)) {
        return props.dataSource
      }
      return []
    })

    // 判断是不是json格式
    function isJson(str) {
      try {
        return JSON.parse(str)
      } catch (e) {
        console.log('error：' + str + '!!!' + e)
        return false
      }
    }

    return {
      ...toRefs(state),
      detail
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-carousel {
  background-color: #fff;
  width: 216px;
  .item {
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  }
  .dotsClass {
    background-color: #f00;
  }
  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    color: #333333;
    line-height: 22px;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    overflow: hidden;
    p {
      width: 174px;
      margin: 0 auto;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
:deep(.slick-dots-bottom) {
  height: 8px;
  bottom: -30px;
  // background-color: #f00;
}
:deep(.slick-dots li.slick-active button) {
  width: 25px;
  height: 8px;
  background: #d9534f;
  border-radius: 4px;
}
:deep(.slick-dots li button) {
  opacity: 1;
  width: 13px;
  height: 8px;
  background: #c9c9c9;
  border-radius: 4px;
}
</style>
