export default{
  title: '面包屑',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/Breadcrumb.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-breadcrumb',
  visible: true,
  form: {
    dataSource: {
      label: '数据源',
      type: 'array-object',
      options: {
        name: {
          label: '页面名称',
          type: 'text'
        },
        path: {
          label: '页面路径',
          type: 'text'
        }
      }
    },
    separator: {
      label: '分隔符',
      type: 'text'
    }
  },
  props: {
    dataSource: [
      { name: '首页', path: '/' },
      { name: '个人中心', path: '/about' },
      { name: '我的', path: '/my' }
    ],
    separator: '/'
  },
  emits: [{ label: '点击一项', value: 'clickItem' }]
}
