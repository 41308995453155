<template>
  <a-input-password v-bind="$attrs">
    <template v-for="(index, name) in $slots" :key="name" #[name]="slotProps">
      <slot :name="name" v-bind="slotProps"></slot>
    </template>
  </a-input-password>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Input } from 'ant-design-vue'
const AInputPassword = Input.Password

export default {
  name: `${prefix}input-password`,
  components: {
    AInputPassword
  }
}
</script>
