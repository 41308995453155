export default {
  title: '滑块',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/Slider.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-slider',
  visible: true,
  form: {},
  props: {}
}
