import Menu from './Menu.vue'
import Item from './Item.vue'
import ItemGroup from './ItemGroup.vue'
import SubMenu from './SubMenu.vue'
import Divider from './Divider.vue'

Menu.Item = Item
Menu.ItemGroup = ItemGroup
Menu.SubMenu = SubMenu
Menu.Divider = Divider

/* istanbul ignore next */
Menu.install = function (Vue) {
  Vue.component(Menu.name, Menu)
  Vue.component(Item.name, Item)
  Vue.component(ItemGroup.name, ItemGroup)
  Vue.component(SubMenu.name, SubMenu)
  Vue.component(Divider.name, Divider)
}

export default Menu
