<template>
  <a-breadcrumb v-bind="$attrs" :separator="separator">
    <a-breadcrumb-item
      v-for="(item, index) in dataSource"
      :key="item.path"
      @click="handleClick(index)"
      >{{ item.name }}</a-breadcrumb-item
    >
  </a-breadcrumb>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Breadcrumb as ABreadcrumb } from 'ant-design-vue'
import { reactive, toRefs, watch } from 'vue'
const ABreadcrumbItem = ABreadcrumb.Item

export default {
  name: `${prefix}breadcrumb`,
  components: {
    ABreadcrumb,
    ABreadcrumbItem
  },
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    separator: {
      type: String,
      default: ''
    }
  },
  emits: ['clickItem'],
  setup(props, { emit }) {
    let state = reactive({
      _dataSource: ['首页', '个人中心', '我的']
    })

    watch(
      () => [...props.dataSource],
      (val) => {
        state._dataSource = val
      }
    )

    const handleClick = (index) => {
      emit('clickItem', index)
    }

    return {
      ...toRefs(state),
      handleClick
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-breadcrumb {
  span {
    :deep(.ant-breadcrumb-link) {
      cursor: pointer;
    }

    &:last-child :deep(.ant-breadcrumb-link) {
      cursor: default;
    }

    :deep(.ant-breadcrumb-link:hover) {
      color: #1890ff;
    }

    &:last-child :deep(.ant-breadcrumb-link:hover) {
      color: inherit;
    }
  }
}
</style>
