// 中台工程
import middlegroundPlatformEvents from '@/projects/middleground/editorInteract/platformEvents'

// 业务示例工程
import {
  businessPlatformEvents,
  businessEditorConfig
} from '@/projects/business/editorInteract/platformEvents.js'

// 聚合自定义方法
function loadPlatformEvents({
  app,
  router,
  isClient,
  initialState,
  request,
  globalState
}) {
  return Object.assign(
    // 中全局变量
    middlegroundPlatformEvents({
      app,
      router,
      isClient,
      initialState,
      request,
      globalState
    }),
    businessPlatformEvents({
      app,
      router,
      isClient,
      initialState,
      request,
      globalState
    })
    // to do ..
  )
}

const editorConfig = [...businessEditorConfig]
// 导出
export { loadPlatformEvents, editorConfig }
