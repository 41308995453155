export default {
  title: '富文本控件',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/RichTextControl.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-rich-text-control',
  visible: true,
  form: {
    data: {
      label: '数据源',
      type: 'text'
    }
  },
  props: {
    data: ''
  }
}
