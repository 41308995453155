/**
 * 聚合中台编写的自定制通用组件以及业务部门开发的自定制中的定制组件
 * @param {type}  name  -  description
 * @param {type}  name  -  description
 * @param {type}  name  -  description
 */
import {
  moduleConfig as publicConfig,
  moduleComponents as publicComponents
} from '@/projects/middleground/components/public'
import {
  moduleConfig as customsConfig,
  moduleComponents as customsComponents
} from '@/projects/business/components/customs'

const allConfig = [...publicConfig, ...customsConfig]

const allComponents = [...publicComponents, ...customsComponents]

export { allConfig, allComponents }
