import routes from './routes'
import { getAppConfig, getPageContent } from '../api/app'
import { generateRoutes, formatRoutesConfig } from '@tcc-ofa/render'
import eventBus from '@/common/utils/eventBus'
import RenderApp from '../views/devOnline/render/RenderApp.vue'

const getPage = async (route, appUnique, initialState) => {
  const page = initialState.$pages[route.name]
  if (page && !page.contentPro) {
    initialState.$pages[route.name] = await getPageContent(
      appUnique,
      route.name
    )
  }
}

function routerHook(router, initialState, globalState) {
  router.beforeEach(async (to, from, next) => {
    try {
      let appUnique = APP_ID ? APP_ID : to.path.split('/')[1]
      console.log('=>>>>>>>>>>>>>>>>>>>>>>', appUnique)
      if (
        appUnique &&
        appUnique.indexOf('APP_') === 0 &&
        !router.hasRoute(to.name) &&
        !initialState.$routes
      ) {
        // 应用唯一标识是否存在
        // let env = to.query.env
        // if (env == null) {
        //   env = sessionStorage.getItem('render-env')
        // } else {
        //   sessionStorage.setItem('render-env', env)
        // }
        // console.log('render-env', env)
        let { data: pageConfig } = await getAppConfig(appUnique)
        //判断应用是否存在扩展信息，若存在则设置为全局变量
        if (pageConfig.content) {
          pageConfig.content = JSON.parse(pageConfig.content)
          Object.keys(pageConfig.content).forEach((key) => {
            globalState[key] = pageConfig.content[key]
          })
        }
        //获取应用配置的统一请求头
        if (pageConfig.header) {
          pageConfig.header = JSON.parse(pageConfig.header)
        }
        sessionStorage.setItem(
          'reqHeader',
          JSON.stringify(pageConfig.header || {})
        )

        // pageConfig['openSilence'] = true //暂时先固定

        let htmlDom = document.getElementsByTagName('html')[0]
        if (pageConfig.openSilence) {
          htmlDom.style =
            "-webkit-filter: grayscale(100%);-moz-filter: grayscale(100%);-ms-filter: grayscale(100%);-o-filter: grayscale(100%);filter: grayscale(100%);filter: url(data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter i…0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale);filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);"
        } else {
          htmlDom.style = ''
        }

        // console.log('=>>>>>>>>>>>>>>>', data)
        initialState.$routes = pageConfig
        // initialState.$pages = pageConfig.pageList
        // eventBus.emit('loadEnd', true)
      } else {
        // eventBus.emit('loadEnd', true)
      }

      if (!router.hasRoute(to.name) && initialState.$routes) {
        initialState.$pages = {}
        let routes = generateRoutes(
          formatRoutesConfig(
            initialState.$routes,
            initialState.$pages,
            !APP_ID
          ),
          RenderApp
        )
        console.log('routes: ', routes)
        // 动态添加可访问路由表
        for (let i = 0; i < routes.length; i++) {
          router.addRoute(routes[i])
        }

        next({ ...to, replace: true })
      } else {
        if (appUnique && appUnique.indexOf('APP_') === 0) {
          const tasks = []
          to.matched.forEach((route) => {
            tasks.push(getPage(route, appUnique, initialState))
          })
          await Promise.all(tasks)
        } else {
          console.log('在编辑器中，初始化用户信息')
          //初始化设置用户登录信息
          globalState['userInfo'] = {
            area: '天河',
            birthdate: new Date(),
            certificateType: 0,
            city: '广州',
            encryptIdCard: '**************',
            encryptRealName: '佚名',
            gender: 0,
            headPic:
              'https://img0.baidu.com/it/u=4144801219,1846048407&fm=26&fmt=auto&gp=0.jpg',
            mobilePhone: '13********',
            nickName: '佚名',
            province: '广东',
            realName: '佚名',
            verifyTag: 0
          }
        }

        next()
      }
    } catch (error) {
      console.error(error)
      // redirect to error route
      next()
    }
  })

  router.afterEach(() => {
    eventBus.emit('loadEnd', true)
  })
}
export { routes, routerHook }
