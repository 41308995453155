<template>
  <a-select-opt-group v-bind="$attrs">
    <slot></slot>
  </a-select-opt-group>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Select } from 'ant-design-vue'
const ASelectOptGroup = Select.OptGroup

export default {
  name: `${prefix}select-opt-group`,
  components: {
    ASelectOptGroup
  }
}
</script>
