<template>
  <div class="sub-menu-container" :style="{ height: containerHeight + 'px' }">
    <div
      v-for="(item, index) in dataSource"
      :key="index"
      class="sub-menu-item"
      :style="{ marginLeft: index > 0 ? '10px' : 0 }"
      :class="{ activeItem: activeItem(item) }"
      @click="handleClickItem(item)"
    >
      {{ item[itemContent] }}
    </div>
  </div>
</template>
<script>
import { reactive, toRef, watch } from 'vue'
import { prefix } from '@business/utils/index.js'

export default {
  name: `${prefix}sub-menu`,
  props: {
    containerHeight: {
      type: Number,
      default: 46
    },
    itemSpace: {
      type: Number,
      default: 10
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemContent: {
      type: String,
      default: 'title'
    },
    defaultActiveKey: {
      type: [String, Number]
    }
  },
  emits: ['click-item', 'source-change', 'change'],
  setup(props, { emit }) {
    const state = reactive({
      activeKey: ''
    })

    watch(
      () => props.defaultActiveKey,
      (newV) => {
        state.activeKey = newV
      },
      {
        immediate: true
      }
    )

    watch(
      () => props.dataSource,
      (newV) => {
        if (
          Object.prototype.toString.call(newV) === '[object Array]' &&
          newV.length
        ) {
          let item
          if (state.activeKey) {
            item = newV?.filter(
              (ele) => ele[props.itemKey] === state.activeKey
            )[0]
          } else {
            item = props.dataSource ? props.dataSource[0] : null
          }
          // console.log("子菜单组件=====", item);
          emit('source-change', item, state.activeKey)
        }
      }
    )

    watch(
      () => state.activeKey,
      (activeKey) => {
        emit('change', activeKey)
      },
      {
        immediate: true
      }
    )

    function handleClickItem(item) {
      state.activeKey = item[props.itemKey]
      emit('click-item', item, state.activeKey)
    }

    function activeItem(item) {
      return item[props.itemKey] === state.activeKey
    }

    return {
      ...toRef(state),
      handleClickItem,
      activeItem
    }
  }
}
</script>

<style scoped lang="scss">
.sub-menu-container {
  display: flex;
  .sub-menu-item {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    background: #d9534f;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #333;
      background-color: #fff;
    }
  }
  .activeItem {
    color: #333;
    background-color: #fff;
  }
}
</style>
