export default{
  title: '丽水-走马灯',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/LSCarousel.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-ls-carousel',
  visible: true,
  form: {
    dataSource: {
      label: '数据源',
      type: 'text'
    },
    dotPosition: {
      label: '动画效果',
      type: 'radio',
      options: [
        {
          label: 'top',
          value: 'top'
        },
        {
          label: 'right',
          value: 'right'
        },
        {
          label: 'left',
          value: 'left'
        },
        {
          label: 'bottom',
          value: 'bottom'
        }
      ]
    },
    imgHeight: {
      label: '图片高度',
      type: 'text'
    },
    compatibleProps: {
      label: '兼容属性',
      children: {
        image: {
          label: '图片',
          type: 'text'
        },
        title: {
          label: '标题',
          type: 'text'
        }
      }
    },
    autoplay: {
      label: '自动播放',
      type: 'switch'
    },
    effects: {
      label: '切换效果',
      type: 'radio',
      options: [
        { label: '位移切换', value: 'scrollx' },
        { label: '淡入', value: 'fade' }
      ]
    }
  },
  props: {
    dataSource: `[
      {
        "image":"https://t7.baidu.com/it/u=1819248061,230866778&fm=193&f=GIF",
        "title":"aaaaaaabbbbbcccddd"
      },
      {
        "image":"https://t7.baidu.com/it/u=963301259,1982396977&fm=193&f=GIF",
        "title":"22222221111111111"
      },
      {"image":"https://t7.baidu.com/it/u=1819248061,230866778&fm=193&f=GIF"}]`,
    dotPosition: 'bottom',
    imgHeight: 255,
    compatibleProps: {
      image: 'image',
      title: 'title'
    },
    effects: 'scrollx',
    autoplay: false
  }
}
