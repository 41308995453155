<template>
  <div
    class="roll-exhibition-module"
    :style="{
      width: width,
      height: height,
      ...getCss
    }"
  >
    <ofa-swiper :data-source="detail" v-bind="$attrs">
      <template #default="{ data }">
        <slot :data="data">
          <!-- <ofa-card :dataSource="data"></ofa-card> -->
        </slot>
      </template>
    </ofa-swiper>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { prefix } from '@business/utils/index.js'
import swiper from '../roll'
// import SpecialShowCard from "../jdy-special-show-card";

export default {
  name: `${prefix}roll-special-show`,
  components: {
    'ofa-swiper': swiper
    // "ofa-card": SpecialShowCard
  },
  props: {
    dataSource: {
      type: String,
      default: `[
      {
        "id": "1",
        "label": "党政公开"
      },
      {
        "id": "1",
        "label": "党政公开"
      },
      {
        "id": "1",
        "label": "党政公开"
      },
      {
        "id": "1",
        "label": "党政公开"
      },
      {
        "id": "1",
        "label": "党政公开"
      },
      {
        "id": "1",
        "label": "党政公开"
      }
    ]`
    },
    width: {
      type: String,
      default: '1200px'
    },
    height: {
      type: String,
      default: '260px'
    },
    arrowWidth: {
      type: String,
      default: '56px'
    },
    arrowHeight: {
      type: String,
      default: '108px'
    },
    arrowTop: {
      type: String,
      default: '104px'
    },
    arrowLeft: {
      type: String,
      default: '0'
    },
    arrowRight: {
      type: String,
      default: '0'
    },
    arrowFontSize: {
      type: String,
      default: '16px'
    }
  },
  setup(props) {
    onMounted(() => {})

    const getCss = computed(() => {
      return {
        '--arrowWidth': props.arrowWidth,
        '--arrowHeight': props.arrowHeight,
        '--arrowTop': props.arrowTop,
        '--arrowLeft': props.arrowLeft,
        '--arrowRight': props.arrowRight,
        '--arrowFontSize': props.arrowFontSize
      }
    })

    const detail = computed(() => {
      if (typeof props.dataSource == 'string') {
        return isJson(props.dataSource)
      } else if (Array.isArray(props.dataSource)) {
        return props.dataSource
      }
      return []
    })

    function isJson(str) {
      try {
        return JSON.parse(str)
      } catch (e) {
        console.log('error：' + str + '!!!' + e)
        return false
      }
    }

    return {
      detail,
      getCss
    }
  }
}
</script>

<style lang="scss" scoped>
.roll-exhibition-module {
  padding: 0 28px;
  position: relative;
}
.swiper-module {
  width: 1140px;
}
:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
  background: rgba(0, 0, 0, 0.36);
  color: #ffffff;
  top: var(--arrowTop);
  width: var(--arrowWidth);
  height: var(--arrowHeight);
  outline: none;
}
:deep(.swiper-button-next) {
  right: var(--arrowRight);
}
:deep(.swiper-button-prev) {
  left: var(--arrowLeft);
}
:deep(.swiper-button-next::after),
:deep(.swiper-button-prev::after) {
  font-size: var(--arrowFontSize);
}
</style>
