export default{
  title: '轮播-专场',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/packages/roll-special-show/RollSpecialShow.vue',
  version: '0.0.1',
  type: 'container',
  componentName: 'ofa-roll-special-show',
  visible: true,
  form: {
    dataSource: {
      label: '数据项',
      type: 'text'
    },
    width: {
      label: '容器宽度',
      type: 'text'
    },
    height: {
      type: 'text',
      label: '容器高度'
    },
    slidesPerView: {
      label: '内容个数',
      type: 'text'
    },
    slidesPerGroup: {
      label: '切换个数',
      type: 'text'
    },
    spaceBetween: {
      label: '内容左右间距',
      type: 'text'
    },
    hasNavigation: {
      type: 'switch',
      label: '是否有箭头'
    },
    arrowWidth: {
      type: 'text',
      label: '箭头区域-宽'
    },
    arrowHeight: {
      type: 'text',
      label: '箭头区域-高'
    },
    arrowTop: {
      label: '箭头距离顶部距离',
      type: 'text'
    },
    arrowLeft: {
      label: '左箭头距左距离',
      type: 'text'
    },
    arrowRight: {
      type: 'text',
      label: '右箭头距右距离'
    },
    arrowFontSize: {
      type: 'text',
      label: '箭头尺寸'
    },
    autoplay: {
      label: '自动滚动',
      type: 'switch'
    }
  },
  props: {
    dataSource: `[
      {
        "id": "1",
        "tips": "#书单·15本",
        "image": "https://img1.baidu.com/it/u=2772931022,2297626731&fm=26&fmt=auto&gp=0.jpg",
        "title": "文津图书推荐"
      },
      {
        "id": "1",
        "tips": "#书单·15本",
        "image": "https://img1.baidu.com/it/u=2772931022,2297626731&fm=26&fmt=auto&gp=0.jpg",
        "title": "文津图书推荐"
      },
      {
        "id": "1",
        "tips": "#书单·15本",
        "image": "https://img1.baidu.com/it/u=2772931022,2297626731&fm=26&fmt=auto&gp=0.jpg",
        "title": "文津图书推荐"
      }
    ]`,
    height: '260px',
    width: '1200px',
    hasNavigation: true,
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 8,
    arrowWidth: '56px',
    arrowHeight: '108px',
    arrowTop: '104px',
    arrowLeft: '56px',
    arrowRight: '108px',
    arrowFontSize: '16px',
    autoplay: false
  }
}
