<template>
  <a-input v-model:value="value" v-bind="$attrs" @change="hanleChange">
    <template v-for="(index, name) in $slots" :key="name" #[name]="slotProps">
      <slot :name="name" v-bind="slotProps"></slot>
    </template>
  </a-input>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Input as AInput } from 'ant-design-vue'
import { reactive, toRefs } from 'vue'

export default {
  name: `${prefix}input`,
  components: {
    AInput
  },
  props: {
    inputValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:inputValue'],
  setup(props, { emit }) {
    const state = reactive({
      value: ''
    })

    const hanleChange = () => {
      emit('update:inputValue', state.value)
    }

    return {
      hanleChange,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped></style>
