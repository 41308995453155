import routes from './routes'
import { getVisitLog } from '@/projects/business/utils/collection.js'
function routerHook(router) {
  router.beforeEach(async (to, from, next) => {
    //页面跳转时调用页面采集接口
    if (to !== from) {
      // console.log('监听路由跳转', to)
      getVisitLog()
    }
    next()
  })
  router.afterEach(() => {})
}
export { routes, routerHook }
