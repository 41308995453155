<template>
  <a-sub-menu v-bind="$attrs">
    <template v-for="(index, name) in $slots" :key="name" #[name]="slotProps">
      <slot :name="name" v-bind="slotProps"></slot>
    </template>
  </a-sub-menu>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Menu as AMenu } from 'ant-design-vue'
const ASubMenu = AMenu.SubMenu

export default {
  name: `${prefix}sub-menu`,
  components: {
    ASubMenu
  }
}
</script>
