<template>
  <a-select
    v-model:value="activeValue"
    v-bind="$attrs"
    :placeholder="placeholder"
    style="width: 100%"
    @change="onchange"
  >
    <a-select-option
      v-for="item in detail"
      :key="item[compatibleProps.value]"
      :value="item[compatibleProps.value]"
      @click="itemClick(item, index)"
      >{{ item[compatibleProps.label] }}</a-select-option
    >
  </a-select>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Select as ASelect } from 'ant-design-vue'
import { reactive, toRefs, computed, watch } from 'vue'
const ASelectOption = ASelect.Option

export default {
  name: `${prefix}select`,
  components: {
    ASelect,
    ASelectOption
  },
  props: {
    dataSource: {
      type: [String, Array],
      default: () => []
    },
    // 兼容属性
    compatibleProps: {
      type: Object,
      default: () => {
        return {
          label: 'label',
          value: 'value'
        }
      }
    },
    placeholder: {
      type: String
    },
    defaultValue: {
      type: String,
      default: null
    },
    selectKey: String //记录点击的子项标识,
  },
  emits: ['itemClick', 'update:selectKey'],
  setup(props, { emit }) {
    let state = reactive({
      activeValue: null
    })

    const detail = computed(() => {
      if (typeof props.dataSource == 'string') {
        return isJson(props.dataSource)
      } else if (Array.isArray(props.dataSource)) {
        return props.dataSource
      }
      return []
    })

    //当defaultValue为空，则显示提示语
    watch(
      () => props.defaultValue,
      (val) => {
        state.activeValue = val
        emit('update:selectKey', state.activeValue)
        // console.log(state.activeValue, "state.activeValueval");
      },
      { immediate: true } //初始化也执行这个watch
    )

    // 判断是不是json格式
    function isJson(str) {
      try {
        return JSON.parse(str)
      } catch (e) {
        console.log('error：' + str + '!!!' + e)
        return false
      }
    }

    const itemClick = () => {
      emit('itemClick')
    }

    const onchange = () => {
      emit('update:selectKey', state.activeValue)
    }

    return {
      ...toRefs(state),
      detail,
      itemClick,
      onchange
    }
  }
}
</script>
