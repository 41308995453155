<template>
  <a-input-search v-model:value="inputValue" v-bind="$attrs">
    <slot></slot>
  </a-input-search>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Input } from 'ant-design-vue'
const AInputSearch = Input.Search

export default {
  name: `${prefix}input-search`,
  components: {
    AInputSearch
  },
  props: {
    historyText: String
  },
  data() {
    return { inputValue: '' }
  },
  watch: {
    historyText: {
      handler(val) {
        if (val !== '' || val !== ' ') {
          this.inputValue = val
        }
      },
      immediate: true
    }
  }
}
</script>
