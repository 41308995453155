<template>
  <a-input-group v-bind="$attrs">
    <slot></slot>
  </a-input-group>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Input } from 'ant-design-vue'
const AInputGroup = Input.Group

export default {
  name: `${prefix}input-group`,
  components: {
    AInputGroup
  }
}
</script>
