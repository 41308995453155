<template>
  <a-pagination v-bind="$attrs"></a-pagination>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Pagination as APagination } from 'ant-design-vue'

export default {
  name: `${prefix}pagination`,
  components: {
    APagination
  }
}
</script>
