<template>
  <div class="p-relative text-center">
    <slot name="icon" />
    <img v-if="hasDefaultImg" width="180" height="233" :src="emptyImg" alt="" />
    <div class="empty">{{ msg }}</div>
  </div>
</template>

<script>
import { prefix } from '@business/utils/index.js'
export default {
  name: `${prefix}empty`, // 统一前缀: ofa-
  props: {
    msg: {
      type: String,
      default: '空空如也～'
    },
    hasDefaultImg: {
      type: Boolean,
      default: false
    },
    emptyImg: {
      type: String,
      default: import.meta.globEager('../image/empty.png')
    }
  }
}
</script>

<style scoped lang="scss">
.empty {
  font-size: 16px;
  margin-top: 80px;
}
</style>
