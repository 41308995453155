<template>
  <a-select-option v-bind="$attrs">
    <slot></slot>
  </a-select-option>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Select } from 'ant-design-vue'
const ASelectOption = Select.Option

export default {
  name: `${prefix}select-option`,
  components: {
    ASelectOption
  }
}
</script>
