/*!
 * 应用编辑器自定义事件
 * 专门用于应用编辑器事件操作
 */
import { defineAsyncComponent } from 'vue'
import { createProperty } from '@/common/api/hooks/createProperty'
import { setToken, setUserInfo } from '../utils/login'
import { message } from '@lcdp-atom-lib/pc'
import { reloadPage } from '@/common/utils/app'
// import X2JS from 'x2js'
// import axios from '@/common/api/hooks/request'
import CryptoJS from 'crypto-js'
import { decrypt } from '@business/utils/DesCrypto'
import { getCurrentDate } from '@business/utils/date'
import {
  getToken,
  setLoginTime,
  getUserInfo,
  getLoginTime
} from '@/projects/business/utils/login'
import dayjs from 'dayjs'
/**
 * 触发时机为在应用初始化完成前
 * @param {type}  app  -  当前应用实例
 * @param {type}  router  -  当前路由对象
 * @param {type}  isClient  -  是否是客户端的标识
 * @param {type}  initialState  -  页面设置的初始化变量信息
 * @param {type}  request  -  服务端请求，在客户端的时候为null
 * @param {type}  globalState  -  全局变量
 */
function loadPlatformEvents({
  app,
  router,
  isClient,
  initialState,
  request,
  globalState
}) {
  console.log(app, router, isClient, initialState, request)
  let loginApp
  const [loginDialog] = createProperty(
    'loginDialog',
    defineAsyncComponent(() => import('../components/login/Login.vue'))
  )

  return {
    // 自定义方法
    //自定义方法：登录
    login: () => {
      //判断当前是否已经挂载该组件，如果已挂载则直接返回
      if (!isClient || loginApp) return
      const onSuccess = (info) => {
        setUserInfo(info)
        console.log('info', info)
        // setToken(info.rdid)
        // axios
        //   .get('/tcc-user-center/client-api/userCenter/infoEnc')
        //   .then((res) => {
        //     console.log(res, '用户信息')
        //     if (res.data?.headPic?.indexOf('http') == -1) {
        //       res.data.headPic =
        //         process.env.VITE_OSS_DOMAIN + res.data.headPic
        //       setUserInfo(res.data)
        //     } else {
        //       setUserInfo(res.data)
        //     }
        //     globalState.userInfo = res.data
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //   })
        globalState.isAuth = true
        loginApp.unmount()
        loginApp = null
        reloadPage()
      }

      const onClose = () => {
        loginApp.unmount()
        loginApp.visible = false
        loginApp = null
        console.log(loginApp, '点击了')
      }

      loginApp = loginDialog({
        visible: true,
        onSuccess,
        onClose
      })
    },
    //自定义方法：注销
    logout: (data, event, { needReload }) => {
      var newTab = window.open(
        'http://www.lslibrary.com:8086/opac/reader/logout'
      )
      setTimeout(() => {
        newTab.close()
        message.success('注销成功')
        if (isClient && needReload) {
          reloadPage()
          window.location.reload()
        }
        setToken('')
        setUserInfo('{}')
        globalState.userInfo = {}
        globalState.isAuth = false
      }, 500)
    },
    checkLoginExpired: () => {
      if (getToken()) {
        let loginTime = getLoginTime()
        if (loginTime) {
          if (dayjs(loginTime).add(1, 'h').unix() < dayjs().unix()) {
            //登录过期
            var newTab = window.open(
              'http://www.lslibrary.com:8086/opac/reader/logout'
            )
            setTimeout(() => {
              newTab.close()
              message.error('登录过期')
              setToken('')
              setUserInfo('{}')
              globalState.userInfo = {}
              globalState.isAuth = false
              reloadPage()
              window.location.reload()
            }, 500)
          }
        }
      }
    },
    //自定义方法：跳转OPAC个人中心
    openOpacInfoPage: () => {
      // const statickey = window.localStorage.getItem('OpacStatickey')
      // const rdid = window.localStorage.getItem('OpacRdid')
      // const AESPassword = window.localStorage.getItem('AESPassword')
      // const password = decrypt(AESPassword)
      // //获取当前时间
      // const currentTime = getCurrentDate('yyyyMMddHHss')
      // // md5(rdid+statickey+rdpwd+time) rdpwd:读者登录密码
      // const afterEncrypt = CryptoJS.MD5(
      //   rdid + statickey + password + currentTime
      // ).toString()
      // let opacFurl = 'http://www.lslibrary.com:8086/opac/reader/getReaderInfo'
      // let aTag = document.createElement('a')
      // aTag.setAttribute(
      //   'href',
      //   'http://www.lslibrary.com:8086/opac/websearch/commonlogin?rdid=' +
      //     rdid +
      //     '&passwd=' +
      //     afterEncrypt +
      //     '&time=' +
      //     currentTime +
      //     '&method=2&furl=' +
      //     opacFurl
      // )
      // aTag.setAttribute('target', '_blank')
      // aTag.click()
      // aTag.remove()
      let url = 'http://www.lslibrary.com:8086/opac/reader/getReaderInfo'
      let strSysDatetime = dayjs().format('YYYY-MM-DDHH:mm:ss')
      setLoginTime(strSysDatetime)
      const userInfo = getUserInfo()
      const verify = `${userInfo.rdid}LSSTSG${strSysDatetime}${userInfo.displayname}`
      const afterVerify = CryptoJS.MD5(verify).toString()
      window.open(
        `http://www.lslibrary.com:8086/opac/websearch/commonlogin/process?userName=${userInfo.rdid}&jsName=${userInfo.displayname}&verify=${afterVerify}&strSysDatetime=${strSysDatetime}&url=${url}`
      )
    },
    //自定义方法：跳转opac
    opacJump: (data, event, { url }) => {
      const token = getToken()
      console.log('使用外链跳转', token)
      if (token) {
        let strSysDatetime = dayjs().format('YYYY-MM-DDHH:mm:ss')
        setLoginTime(strSysDatetime)
        const userInfo = getUserInfo()
        const verify = `${userInfo.rdid}LSSTSG${strSysDatetime}${userInfo.displayname}`
        const afterVerify = CryptoJS.MD5(verify).toString()
        window.open(
          `http://www.lslibrary.com:8086/opac/websearch/commonlogin/process?userName=${userInfo.rdid}&jsName=${userInfo.displayname}&verify=${afterVerify}&strSysDatetime=${strSysDatetime}&url=${url}`
        )
      } else {
        window.open(url)
      }
    }
  }
}
/**
 * 此配置可让编辑器选择对应事件做绑定
 * label 对应显示的名称
 * value 对应自定义事件的方法名
 */
let loadEditorConfig = [
  { label: '登录', value: 'login' },
  { label: '注销', value: 'logout' },
  { label: '跳转OPAC个人中心', value: 'openOpacInfoPage' },
  { label: '跳转opac外链', value: 'opacJump' },
  { label: '登录过期检测', value: 'checkLoginExpired' }
]

export {
  loadPlatformEvents as businessPlatformEvents,
  loadEditorConfig as businessEditorConfig
}
