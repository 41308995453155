<template>
  <a-menu-item-group v-bind="$attrs">
    <slot></slot>
  </a-menu-item-group>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Menu as AMenu } from 'ant-design-vue'
const AMenuItemGroup = AMenu.ItemGroup

export default {
  name: `${prefix}menu-item-group`,
  components: {
    AMenuItemGroup
  }
}
</script>
