export default {
  title: '列表',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/packages/list/List.vue',
  version: '0.0.1',
  type: 'container',
  componentName: 'ofa-list',
  visible: true,
  form: {
    grid: {
      label: '栅格列表',
      children: {
        gutter: { type: 'text', label: '栅格间隔' },
        column: { type: 'text', label: '列数' }
      }
    },
    dataSource: {
      label: '数据项',
      type: 'text'
    },
    itemLayout: {
      label: '布局',
      type: 'text'
    },
    size: {
      label: 'list 的尺寸',
      type: 'text'
    },
    split: {
      label: '是否显示分割线',
      type: 'switch'
    },
    paginationVisible: {
      label: '是否显示分页器',
      type: 'switch'
    },
    pagination: {
      label: '分页器默认配置',
      children: {
        disabled: { type: 'radio', label: '禁用分页' },
        defaultPageSize: { type: 'radio', label: '默认的每页条数' },
        hideOnSinglePage: { type: 'radio', label: '一页时是否隐藏分页器' }
      }
    },
    pageSize: {
      label: '每页条数',
      type: 'text'
    },
    current: {
      label: '当前页数',
      type: 'text'
    },
    maxSize: {
      label: '最大个数',
      type: 'text'
    },
    emptyMes: {
      label: '空文本',
      type: 'text'
    },
    useEmpty: {
      label: '是否显示空状态',
      type: 'switch'
    },
    emptyImg: {
      label: '空图片',
      type: 'text'
    },
    loadMoreContent: {
      label: '下拉加载',
      type: 'switch'
    },
    isLoading: {
      label: '是否在加载中',
      type: 'switch'
    }
  },
  model: {
    dataSource: {
      label: '数据源',
      value: 'dataSource'
    },
    current: {
      label: '页码',
      value: 'current'
    },
    pageSize: {
      label: '页码数量',
      value: 'pageSize'
    }
  },
  props: {
    grid: {
      gutter: 16,
      column: 4
    },
    itemLayout: 'horizontal',
    size: 'default',
    split: false,
    dataSource: [],
    paginationVisible: false,
    pagination: {
      disabled: false,
      defaultPageSize: 10,
      hideOnSinglePage: true
    },
    maxSize: 0,
    pageSize: 10,
    current: 1,
    useEmpty: true, //是否显示空状态
    emptyMes: '暂无数据~',
    emptyImg:
      'https://tcc-ofa-dev.oss-cn-shenzhen.aliyuncs.com/gys_tc/20210810/c7de0781-623f-475f-865a-55333640e76f.png',
    loadMoreContent: false,
    isLoading: false
  },
  emits: [{ label: '重新请求', value: 'request' }]
}
