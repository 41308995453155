<template>
  <div class="pagination-container" :style="getCss">
    <a-button size="large" :disabled="current === 1" @click="fisterChange"
      >首页</a-button
    >
    <ConfigProvider :locale="locale">
      <a-pagination
        v-model:current="current"
        v-model:page-size="pageSize"
        :total="total"
        @change="onChange"
      >
        <template v-if="isItemRender" #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'">上一页</a>
          <a v-else-if="type === 'next'">下一页</a>
          <renderVNode v-else :vnode="originalElement"></renderVNode>
        </template>
      </a-pagination>
    </ConfigProvider>
    <a-button
      size="large"
      :disabled="current === lastPage || lastPage === 0"
      @click="lastChange"
      >尾页</a-button
    >
  </div>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import {
  Pagination as APagination,
  ConfigProvider,
  Button as AButton
} from 'ant-design-vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { computed, reactive, toRefs, watch } from 'vue'
// import moment from 'moment'
// import 'moment/locale/zh-cn'
// moment.locale('zh-cn')
function renderVNode(_, { attrs: { vnode } }) {
  return vnode
}
export default {
  name: `${prefix}ls-pagination`,
  components: {
    APagination,
    ConfigProvider,
    renderVNode,
    AButton
  },

  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    sizeNumber: {
      type: Number,
      default: 1
    },
    isItemRender: {
      type: Boolean,
      default: false
    },
    activeColor: {
      type: String,
      default: ''
    },
    defaultColor: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 100
    }
  },

  emits: ['onChange', 'update:currentPage'],

  setup(props, { emit }) {
    const state = reactive({
      locale: zhCN,
      current: 1,
      lastPage: null, //最后一页
      timeout: null //定时器判断标识
    })

    //更改高亮颜色
    const getCss = computed(() => {
      return {
        '--activeColor': props.activeColor,
        '--defaultColor': props.defaultColor
      }
    })

    const pageSize = computed(() => {
      return props.sizeNumber
    })

    //更改total或pageSize时改变尾页的值
    watch(
      () => props.total,
      (totalVal) => {
        state.lastPage = Math.ceil(totalVal / props.sizeNumber)
      },
      { immediate: true }
    )
    watch(
      () => props.sizeNumber,
      (pageVal) => {
        state.lastPage = Math.ceil(props.total / pageVal)
      },
      { immediate: true }
    )

    // 返回首页
    const fisterChange = () => {
      state.current = 1
      emit('update:currentPage', state.current)
      emit('onChange')
    }
    //返回尾页
    const lastChange = () => {
      state.lastPage = Math.ceil(props.total / props.sizeNumber)
      state.current = state.lastPage
      emit('update:currentPage', state.current)
      emit('onChange')
    }

    const onChange = (val) => {
      emit('update:currentPage', val)
      emit('onChange', val)
    }
    return {
      ...toRefs(state),
      pageSize,
      onChange,
      fisterChange,
      lastChange,
      getCss
    }
  }
}
</script>
<style scoped lang="scss">
:deep(.ant-pagination-item),
:deep(.ant-pagination-next),
:deep(.ant-pagination-prev),
:deep(.ant-pagination-options-quick-jumper) {
  line-height: 38px;
  height: 38px !important;
  min-width: 38px;
  text-align: center;
}
.pagination-container {
  // background-color: #ceeeee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
:deep(.ant-pagination) {
  // background-color: #f0f0f0;
  margin: 0 10px;
}
:deep(.ant-btn-lg) {
  height: 38px;
  border-radius: 4px;
}
:deep(.ant-pagination-next),
:deep(.ant-pagination-prev) {
  // border: 1px solid #e3e2e2;
  border-radius: 4px;
}
//button禁用状态
:deep(.ant-btn[disabled]) {
  background-color: #fff;
  color: #ccc;
  border-color: #e3e2e2;
}
:deep(.ant-pagination-item),
:deep(.ant-pagination-options-quick-jumper input),
:deep(.ant-pagination-item-link) {
  background-color: var(--defaultColor);
}
:deep(.ant-pagination-prev a),
:deep(.ant-pagination-next a) {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: block;
  height: 100%;
  // width: 88px;
  background-color: var(--defaultColor);
}
:deep(.ant-pagination-item:hover),
:deep(.ant-select:not(.ant-select-disabled):hover .ant-select-selector),
:deep(.ant-pagination-options-quick-jumper input:hover) {
  border-color: var(--activeColor);
}
:deep(.ant-pagination-item:hover a),
:deep(.ant-pagination-item-container .ant-pagination-item-link-icon) {
  color: var(--activeColor);
}
:deep(.ant-pagination-next:hover .ant-pagination-item-link),
:deep(.ant-pagination-prev:hover .ant-pagination-item-link),
:deep(.ant-pagination-next:focus .ant-pagination-item-link),
:deep(.ant-btn:hover) {
  border-color: var(--activeColor);
  color: var(--activeColor);
}
:deep(.ant-pagination-item-active) {
  background-color: var(--activeColor);
  border-color: var(--activeColor);
}
:deep(.ant-pagination-item-active a) {
  color: #fff !important; //使当前被选中的li中a的颜色不被hover改变
}
:deep(.ant-pagination-disabled .ant-pagination-item-link) {
  border-color: #d9d9d9 !important; //使disabled的li中a的颜色不被hover改变
  color: rgba(0, 0, 0, 0.85) !important;
}
:deep(.ant-btn[disabled]:hover) {
  color: #ccc; //使button禁用状态时颜色不被hover改变
  border-color: #e3e2e2;
}
</style>
