<template>
  <a-menu-divider v-bind="$attrs">
    <slot></slot>
  </a-menu-divider>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Menu as AMenu } from 'ant-design-vue'
const AMenuDivider = AMenu.Divider

export default {
  name: `${prefix}menu-divider`,
  components: {
    AMenuDivider
  }
}
</script>
