import { prefix } from './const.js'
export { prefix }

/**
 * 随机字符串
 *
 * @return {string}
 */
export const randomStr = () => {
  return Math.random().toString().slice(2)
}
