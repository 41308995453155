import Request from '@/common/api/hooks/request'

// 配置页面采集
export function getVisitLog() {
  return Request.post(
    `/lib-pc-portal/open-api/visitLog`,
    {},
    { showError: false }
  ).then((res) => res.data)
}
