// import Item from "./component/Item.vue";
// import Meta from "./component/Meta.vue";
import List from './List.vue'

// List.Item = { ...Item };
// List.Item.Meta = { ...Meta };

/* istanbul ignore next */
List.install = function (Vue) {
  Vue.component(List.name, List)
  // Vue.component(Item.name, List.Item);
  // Vue.component(Meta.name, List.Item.Meta);
}

export default List

// export { Item, Meta };
