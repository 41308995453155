export default {
  title: '分页',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/Pagination.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-pagination',
  visible: true,
  form: {
    total: {
      label: '数据总数',
      type: 'text'
    },
    showQuickJumper: {
      label: '是否可以快速跳转至某页',
      type: 'switch'
    },
    showSizeChanger: {
      label: '是否可以改变pageSize',
      type: 'switch'
    }
  },
  props: {
    total: 50,
    showQuickJumper: false,
    showSizeChanger: false
  }
}
