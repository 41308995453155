export default{
  title: '轮播',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/packages/roll/Roll.vue',
  version: '0.0.1',
  type: 'container',
  componentName: 'ofa-roll',
  visible: true,
  form: {
    dataSource: {
      type: 'text',
      label: '数据源'
    },
    hasPagination: {
      label: '显示指标',
      type: 'switch'
    },
    hasNavigation: {
      label: '显示左右切换',
      type: 'switch'
    },
    slidesPerView: {
      type: 'text',
      label: '显示个数'
    },
    slidesPerGroup: {
      type: 'text',
      label: '切换个数'
    },
    spaceBetween: {
      type: 'text',
      label: '间隔'
    },
    autoplay: {
      label: '自动滚动',
      type: 'switch'
    },
    autoDelay: {
      label: '播放时间间隔(秒)',
      type: 'number'
    },
    loop: {
      label: '是否循环播放',
      type: 'switch'
    },
    effects: {
      label: '切换效果',
      type: 'select',
      options: [
        { label: '位移切换', value: 'slide' },
        { label: '淡入', value: 'fade' },
        { label: '方块', value: 'cube' },
        { label: '3d流', value: 'coverflow' },
        { label: '3d翻转', value: 'flip' }
      ]
    }
  },
  props: {
    dataSource: [1, 2, 3],
    hasPagination: false,
    hasNavigation: false,
    slidesPerView: '',
    slidesPerGroup: 1,
    spaceBetween: '',
    autoplay: false,
    autoDelay: 3,
    loop: false,
    effects: 'slide'
  }
}
