export default {
  title: '导航菜单',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/Menu.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-menu',
  visible: true,
  form: {
    currentMenuKey: {
      label: '当前选中',
      type: 'text'
    },
    currentopenKey: {
      label: '当前选中展开项',
      type: 'text'
    },
    mode: {
      label: '类型',
      type: 'radio',
      options: [
        {
          label: 'vertical',
          value: 'vertical'
        },
        {
          label: 'vertical-right',
          value: 'vertical-right'
        },
        {
          label: 'horizontal',
          value: 'horizontal'
        },
        {
          label: 'inline',
          value: 'inline'
        }
      ]
    },
    dataSource: {
      label: '数据源',
      type: 'text'
    },
    hasItemArrow: {
      label: '是否显示箭头',
      type: 'switch'
    },
    activeColor: {
      label: '高亮颜色',
      type: 'text'
    },
    compatibleProps: {
      label: '兼容属性',
      children: {
        id: {
          label: 'ID',
          type: 'text'
        },
        title: {
          label: '标题',
          type: 'text'
        },
        children: {
          label: '子标题',
          type: 'text'
        }
      }
    }
  },
  model: {
    currentMenuKey: {
      // 对应下方props
      label: '子项切换id',
      value: 'currentMenuKey' // 在数据绑定弹框中显示的变量名
    },
    currentopenKey: {
      label: '子项切换展开项id',
      value: 'currentopenKey'
    }
  },
  props: {
    mode: 'vertical',
    dataSource: `[
      {
        "id": "0",
        "title": "aa"
      },
      {
        "id": "1",
        "title": "bb",
        "children": [
          {
            "id": "1",
            "title": "bb11"
          },
          {
            "id": "2",
            "title": "bb22"
          }
        ]
      },
      {
        "id": "2",
        "title": "cc"
      },
      {
        "id": "3",
        "title": "dd"
      },
      {
        "id": "4",
        "title": "ee"
      }
    ]`,
    compatibleProps: {
      id: 'id',
      title: 'title',
      children: 'children'
    },
    hasItemArrow: false,
    activeColor: '#D43929',
    currentMenuKey: null,
    currentopenKey: null
  },
  emits: [{ label: '点击子项', value: 'clickItem' }]
}
