import Request from '@/common/api/hooks/request'
// 根据ID查询租户信息
export function getAppConfig(appId, env = 0) {
  return Request.get(`/lcdp-code-reader/client-api/app/simple/${appId}`, {
    // env: 0：生产环境 1：开发环境
    params: { env, treeType: 0 }
  })
}

export function getPageContent(appId, id) {
  return Request.get(
    `/lcdp-code-reader/client-api/app/${appId}/page/${id}`
  ).then((res) => res.data)
}
