import { computed } from 'vue'

const ctx = '@@InfiniteScroll'

const throttle = function (fn, delay) {
  let now, lastExec, timer, context, args

  const execute = function () {
    fn.apply(context, args)
    lastExec = now
  }

  return function () {
    context = this
    args = arguments

    now = Date.now()

    if (timer) {
      clearTimeout(timer)
      timer = null
    }

    if (lastExec) {
      const diff = delay - (now - lastExec)
      if (diff < 0) {
        execute()
      } else {
        timer = setTimeout(() => {
          execute()
        }, diff)
      }
    } else {
      execute()
    }
  }
}

const getScrollTop = function (element) {
  if (element === window) {
    return Math.max(window.pageYOffset || 0, document.documentElement.scrollTop)
  }

  return element.scrollTop
}

const getComputedStyle = document.defaultView.getComputedStyle

const getScrollEventTarget = function (element) {
  let currentNode = element
  // bugfix, see http://w3help.org/zh-cn/causes/SD9013 and http://stackoverflow.com/questions/17016740/onscroll-function-is-not-working-for-chrome
  while (
    currentNode &&
    currentNode.tagName !== 'HTML' &&
    currentNode.tagName !== 'BODY' &&
    currentNode.nodeType === 1
  ) {
    const overflowY = getComputedStyle(currentNode).overflowY
    if (overflowY === 'scroll' || overflowY === 'auto') {
      return currentNode
    }
    currentNode = currentNode.parentNode
  }
  return window
}

const getVisibleHeight = function (element) {
  if (element === window) {
    return document.documentElement.clientHeight
  }

  return element.clientHeight
}

const getElementTop = function (element) {
  if (element === window) {
    return getScrollTop(window)
  }
  return element.getBoundingClientRect().top + getScrollTop(window)
}

const isAttached = function (element) {
  let currentNode = element.parentNode
  while (currentNode) {
    if (currentNode.tagName === 'HTML') {
      return true
    }
    if (currentNode.nodeType === 11) {
      return false
    }
    currentNode = currentNode.parentNode
  }
  return false
}

const doBind = function () {
  if (this.binded) return
  this.binded = true

  const directive = this
  const element = directive.el

  let options = {
    throttleDelay: 200,
    disabled: false,
    immediateCheck: true,
    distance: 0
  }
  directive.onScroll = directive.expression[0]

  if (directive.expression.length >= 2 && typeof directive === 'object') {
    options = {
      ...options,
      ...directive.expression[1],
      disabled: computed(() => directive.expression[1].disabled)
    }
  }

  let throttleDelay = options.throttleDelay
  if (throttleDelay) {
    throttleDelay = Number(throttleDelay)
    if (isNaN(throttleDelay) || throttleDelay < 0) {
      throttleDelay = 200
    }
  }
  directive.throttleDelay = throttleDelay

  directive.scrollEventTarget = getScrollEventTarget(element)
  directive.scrollListener = throttle(
    doCheck.bind(directive),
    directive.throttleDelay
  )
  directive.scrollEventTarget.addEventListener(
    'scroll',
    directive.scrollListener
  )

  directive.disabled = options.disabled
  directive.distance = options.distance
  directive.immediateCheck = options.immediateCheck
  if (options.immediateCheck) {
    doCheck.call(directive)
  }
}

function doCheck(force) {
  const scrollEventTarget = this.scrollEventTarget
  const element = this.el
  const distance = this.distance

  if (force !== true && this.disabled?.value) return
  const viewportScrollTop = getScrollTop(scrollEventTarget)
  const viewportBottom = viewportScrollTop + getVisibleHeight(scrollEventTarget)

  let shouldTrigger = false

  if (scrollEventTarget === element) {
    shouldTrigger = scrollEventTarget.scrollHeight - viewportBottom <= distance
  } else {
    const elementBottom =
      getElementTop(element) -
      getElementTop(scrollEventTarget) +
      element.offsetHeight +
      viewportScrollTop

    shouldTrigger = viewportBottom + distance >= elementBottom
  }

  if (shouldTrigger && this.onScroll) {
    this.onScroll()
  }
}

export default {
  mounted(el, binding) {
    el[ctx] = {
      el,
      expression: binding.value
    }
    const args = arguments
    if (isAttached(el)) {
      doBind.call(el[ctx], args)
    }

    el[ctx].bindTryCount = 0

    const tryBind = function () {
      if (el[ctx].bindTryCount > 10) return
      el[ctx].bindTryCount++
      if (isAttached(el)) {
        doBind.call(el[ctx], args)
      } else {
        setTimeout(tryBind, 50)
      }
    }

    tryBind()
  },
  beforeUnmount(el) {
    if (el && el[ctx] && el[ctx].scrollEventTarget)
      el[ctx].scrollEventTarget.removeEventListener(
        'scroll',
        el[ctx].scrollListener
      )
  }
}
