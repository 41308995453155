export default {
  title: '选择器',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/Select.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-select',
  visible: true,
  form: {
    mode: {
      label: '模式',
      type: 'radio',
      options: [
        {
          label: 'multiple',
          value: 'multiple'
        },
        {
          label: 'tags',
          value: 'tags'
        },
        {
          label: 'combobox',
          value: 'combobox'
        }
      ]
    },
    dataSource: {
      label: '数据源',
      type: 'text'
    },
    allowClear: {
      label: '支持清除',
      type: 'switch'
    },
    placeholder: {
      label: '提示语',
      type: 'text'
    },
    compatibleProps: {
      label: '兼容属性',
      children: {
        value: {
          label: '兼容属性(value)',
          type: 'text'
        },
        label: {
          label: '兼容属性(标题)',
          type: 'text'
        }
      }
    },
    defaultValue: {
      label: '默认选中项的Value(为null时提示词显示',
      type: 'text'
    }
  },
  model: {
    selectKey: {
      label: '子项切换id',
      value: 'selectKey' // 在数据绑定弹框中显示的变量名
    }
  },
  props: {
    mode: '',
    dataSource: `[
      { "label": "11", "value": "1" },
      { "label": "22", "value": "2" },
      { "label": "33", "value": "3" }
    ]`,
    placeholder: '提示语',
    compatibleProps: {
      value: 'value',
      label: 'label'
    },
    allowClear: false,
    selectKey: null,
    defaultValue: null
  },
  emits: [{ label: '点击子项', value: 'itemClick' }]
}
