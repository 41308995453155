import Cookies from 'js-cookie'

export const ACCESS_TOKEN = 'CLIENT-TOKEN'
export const USER_INFO = 'USER_INFO'
export const LOGIN_TIME = 'LOGIN_TIME'
export function getToken() {
  return Cookies.get(ACCESS_TOKEN)
}

export function getLoginTime() {
  return Cookies.get(LOGIN_TIME)
}

export function setLoginTime(time) {
  const seconds = 3600
  const expires = new Date(new Date() * 1 + seconds * 1000)
  Cookies.set(LOGIN_TIME, time, { expires })
}

export function setToken(token) {
  Cookies.set(ACCESS_TOKEN, token, { expires: 7 })
}

export function getUserInfo() {
  if (
    typeof Cookies.get(USER_INFO) == 'string' &&
    Cookies.get(USER_INFO) !== ''
  ) {
    return JSON.parse(Cookies.get(USER_INFO))
  } else {
    return Cookies.get(USER_INFO)
  }
}

export function setUserInfo(val) {
  Cookies.set(USER_INFO, val, { expires: 7 })
}
