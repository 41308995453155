export default {
  title: '丽水-分页',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/LSPagination.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-ls-pagination',
  visible: true,
  form: {
    total: {
      label: '数据总数',
      type: 'text'
    },
    showQuickJumper: {
      label: '是否可以快速跳转至某页',
      type: 'switch'
    },
    showSizeChanger: {
      label: '是否可以改变pageSize',
      type: 'switch'
    },
    hideOnSinglePage: {
      label: '只有一页时是否隐藏分页器',
      type: 'switch'
    },
    isItemRender: {
      label: '是否开启自定义页码',
      type: 'switch'
    },
    currentPage: {
      label: '当前页',
      type: 'number'
    },
    sizeNumber: {
      label: '每页条数',
      type: 'number'
    },
    activeColor: {
      label: '高亮颜色',
      type: 'text'
    },
    defaultColor: {
      label: '默认颜色',
      type: 'text'
    }
  },
  props: {
    total: 100,
    // pageSize: 10,
    sizeNumber: 10,
    showQuickJumper: false,
    showSizeChanger: false,
    hideOnSinglePage: true,
    currentPage: 1,
    isItemRender: false,
    activeColor: '#D9534F',
    defaultColor: '#fff'
  },
  emits: [{ label: '页码改变', value: 'onChange' }],
  model: {
    currentPage: {
      label: '当前页',
      value: 'currentPage'
    }
  }
}
