<template>
  <div ref="dragDiv" class="drag" :style="getCss">
    <div
      class="drag_bg"
      :style="{
        '--draglWidth': handlerWidth
      }"
    ></div>
    <div class="drag_text">{{ confirmWords }}</div>
    <div
      ref="moveDiv"
      :class="{ handler_ok_bg: confirmSuccess }"
      class="handler handler_bg"
      :style="{
        '--handlerLeft': handlerLeft
      }"
      @mousedown="mouseDownFn($event)"
    ></div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'

export default {
  name: `OfaSlider`,
  components: {},
  props: {
    routes: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const state = reactive({
      beginClientX: 0 /* 距离屏幕左端距离 */,
      mouseMoveState: false /* 触发拖动状态  判断 */,
      handlerLeft: '2px',
      handlerWidth: '0px',
      maxWidth: '' /* 拖动最大宽度，依据滑块宽度算出来的 */,
      confirmWords: '向右拖动滑块验证' /* 滑块文字 */,
      confirmSuccess: false /* 验证成功判断 */
    })
    const dragDiv = ref(null)
    const moveDiv = ref(null)

    // mousedown 事件
    const mouseDownFn = (e) => {
      if (!state.confirmSuccess) {
        e.preventDefault && e.preventDefault() // 阻止文字选中等 浏览器默认事件
        state.mouseMoveState = true
        state.beginClientX = e.clientX
      }
    }

    const successFunction = () => {
      state.confirmSuccess = true
      state.confirmWords = '验证成功'
      if (window.addEventListener) {
        document
          .getElementsByTagName('html')[0]
          .removeEventListener('mousemove', mouseMoveFn)
        document
          .getElementsByTagName('html')[0]
          .removeEventListener('mouseup', moseUpFn)
      } else {
        document
          .getElementsByTagName('html')[0]
          .removeEventListener('mouseup', () => {})
      }
      document.getElementsByClassName('drag_text')[0].style.color = '#fff'
      state.handlerLeft = state.maxWidth + 'px'
      state.handlerWidth = state.maxWidth + 'px'
    }

    const mouseMoveFn = (e) => {
      const width = e.clientX - state.beginClientX
      if (state.mouseMoveState) {
        if (width > 0 && width <= state.maxWidth) {
          state.handlerLeft = width + 'px'
          state.handlerWidth = width + 'px'
        } else if (width > state.maxWidth) {
          successFunction()
        }
      }
    }

    const moseUpFn = (e) => {
      state.mouseMoveState = false
      const width = e.clientX - state.beginClientX
      if (width < state.maxWidth) {
        state.handlerLeft = 0 + 'px'
        state.handlerWidth = 0 + 'px'
      }
    }

    onMounted(() => {
      state.maxWidth = dragDiv.value.clientWidth - moveDiv.value.clientWidth - 2
      document
        .getElementsByTagName('html')[0]
        .addEventListener('mousemove', mouseMoveFn)
      document
        .getElementsByTagName('html')[0]
        .addEventListener('mouseup', moseUpFn)
    })

    return {
      ...toRefs(state),
      mouseDownFn,
      successFunction,
      mouseMoveFn,
      moseUpFn,
      dragDiv,
      moveDiv
    }
  }
}
</script>

<style lang="scss" scoped>
.drag {
  position: relative;
  background-color: #e8e8e8;
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  //   border: 1px solid #ccc;
  border-radius: 4px;
}
.handler {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: var(--handlerLeft) !important;
  width: 60px;
  height: 38px;
  background: linear-gradient(
    180deg,
    rgba(242, 242, 242, 1),
    rgba(255, 255, 255, 0)
  );
  border-radius: 4px;
  cursor: move;
}
.handler_bg {
  background: url('./image/login_right_arrow.png') no-repeat center #fff;
  background-size: 16px 16px;
}
.handler_ok_bg {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: url('./image/login_right_icon.png') no-repeat center #fff;
  background-size: 16px 16px;
}
.drag_bg {
  background: linear-gradient(
    270deg,
    rgba(255, 162, 0, 1),
    rgba(249, 204, 108, 1)
  );
  border-radius: 4px;
  height: 100%;
  width: var(--draglWidth) !important;
  border-radius: 4px 0 0 4px;
}
.drag_text {
  position: absolute;
  top: 0px;
  color: rgba(102, 102, 102, 1);
  width: 100%;
  text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
}
</style>
