export default {
  title: '读者咨询',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/ReaderConsult.vue',
  version: '0.0.1',
  type: 'normal',
  componentName: 'ofa-reader-consult',
  visible: true,
  form: {
    codeImage: {
      type: 'text',
      label: '验证码'
    },
    maxWords: {
      type: 'number',
      label: '咨询内容最大字数'
    },
    placeholder: {
      type: 'text',
      label: '咨询内容提示文字'
    }
  },
  props: {
    codeImage: '',
    resForm: {
      desc: '',
      email: '',
      phone: '',
      name: '',
      sex: 1,
      code: ''
    },
    maxWords: 150,
    placeholder: '填写您的咨询内容(150字以内)'
  },
  model: {
    resForm: {
      label: '当前表单提交的数据',
      value: 'resForm'
    }
  },
  emits: [
    { label: '点击提交', value: 'submit' },
    { label: '点击验证码', value: 'changeCode' }
  ]
}
