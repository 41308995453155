<template>
  <div>
    <div :id="'swiper-' + sid" class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in dataSource"
          :key="index"
          class="swiper-slide"
        >
          <slot :data="item">
            <div class="placeholder">placeholder</div>
          </slot>
        </div>
      </div>
      <div v-if="hasPagination" class="swiper-pagination"></div>
    </div>
    <template v-if="hasNavigation">
      <div :class="'sw-' + sid + '-prev swiper-button-prev'"></div>
      <div :class="'sw-' + sid + '-next swiper-button-next'"></div>
    </template>
  </div>
</template>

<script>
import { onMounted, watch } from 'vue'
import { prefix, randomStr } from '@business/utils/index.js'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
export default {
  name: `${prefix}roll`,
  props: {
    dataSource: {
      type: Array,
      default: () => [1, 2, 3]
    },
    hasPagination: {
      type: Boolean,
      default: false
    },
    hasNavigation: {
      type: Boolean,
      default: false
    },
    slidesPerView: {
      type: Number,
      default: 1
    },
    slidesPerGroup: {
      type: Number,
      default: 1
    },
    spaceBetween: {
      type: Number,
      default: 16
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    autoDelay: {
      type: Number,
      default: 3
    },
    loop: {
      type: Boolean,
      default: false
    },
    effects: {
      type: String,
      default: 'slide'
    }
  },
  setup(props) {
    const sid = randomStr()

    // eslint-disable-next-line no-unused-vars
    let ss

    onMounted(() => {
      ss = new Swiper(`#swiper-${sid}`, {
        autoplay: props.autoplay
          ? {
              delay: Number(props.autoDelay * 1000 || 3000),
              stopOnLastSlide: false,
              disableOnInteraction: false
            }
          : false,
        observer: true,
        slidesPerView: Number(props.slidesPerView || 1),
        slidesPerGroup: Number(props.slidesPerGroup || 1),
        spaceBetween: Number(props.spaceBetween || 0),
        pagination: props.hasPagination
          ? {
              el: '.swiper-pagination',
              clickable: true
            }
          : {},
        navigation: props.hasNavigation
          ? {
              nextEl: `.sw-${sid}-next`,
              prevEl: `.sw-${sid}-prev`
            }
          : {},
        loop: props.loop,
        effect: props.effects
      })
    })

    watch(
      () => [...props.dataSource],
      () => {
        if (ss) {
          ss.update(true)
        }
      }
    )

    //更改切换效果
    watch(
      () => props.effects,
      () => {
        ss.params.effect = props.effects
        console.log(ss.params.effect, 'ss.params.effect ')
      }
    )

    //监听轮播是否循环
    watch(
      () => props.loop,
      () => {
        ss.params.loop = props.loop
      }
    )

    return {
      sid
    }
  }
}
</script>

<style lang="scss" scoped>
// @import 'swiper/css/swiper.scss';
.placeholder {
  width: 100%;
  height: 100px;
  font-size: 16px;
  color: #333;
  text-align: center;
  line-height: 100px;
  background-color: #ccc;
}

:deep(.swiper-button-prev.swiper-button-disabled) {
  pointer-events: auto;
}
:deep(.swiper-button-next.swiper-button-disabled) {
  pointer-events: auto;
}
</style>
