<!--
 * @Date: 2021-05-21
 * @Description: UI---富文本控件
-->
<template>
  <div v-html="data"></div>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { reactive, toRefs, onMounted } from 'vue'

export default {
  name: `${prefix}rich-text-control`,
  components: {},
  props: ['data'],

  setup() {
    const state = reactive({})

    onMounted(() => {})

    return {
      ...toRefs(state)
    }
  }
}
</script>
<style scoped lang="scss">
img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
</style>
