<template>
  <a-breadcrumb-item v-bind="$attrs">
    <slot></slot>
  </a-breadcrumb-item>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { Breadcrumb as ABreadcrumb } from 'ant-design-vue'
const ABreadcrumbItem = ABreadcrumb.Item

export default {
  name: `${prefix}breadcrumb-item`,
  components: {
    ABreadcrumbItem
  }
}
</script>

<style lang="scss" scoped></style>
