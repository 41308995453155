export default {
  title: '列表子项',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/packages/list/component/Item.vue',
  version: '0.0.1',
  type: 'container',
  componentName: 'ofa-list-item',
  visible: true,
  form: {},
  props: {}
}
