export default {
  title: '子级菜单',
  icon: 'origin-component-icon/icon_text.png',
  path: '/tcc-ofa-pc/packages/sub-menu/SubMenu.vue',
  version: '0.0.2',
  type: 'normal',
  componentName: 'ofa-sub-menu',
  visible: true,
  form: {
    containerHeight: {
      label: '容器高度(px)',
      type: 'number'
    },
    itemSpace: {
      label: '项间距(px)',
      type: 'number'
    },
    dataSource: {
      label: '数据源',
      type: 'json'
    },
    itemKey: {
      label: '项唯一值',
      type: 'text'
    },
    itemContent: {
      label: '项内容',
      type: 'text'
    },
    defaultActiveKey: {
      label: '默认key',
      type: 'text'
    }
  },
  props: {
    containerHeight: 46,
    itemSpace: 10,
    dataSource: [
      {
        title: '广州',
        id: '1'
      },
      {
        title: '广州',
        id: '2'
      }
    ],
    itemKey: 'id',
    itemContent: 'title',
    defaultActiveKey: '1'
  },
  emits: [
    {
      label: '项点击事件',
      value: 'click-item'
    },
    {
      label: 'change',
      value: 'change'
    },
    {
      label: '数据源改变(初始化)',
      value: 'source-change'
    }
  ]
}
