<template>
  <div
    class="roll-exhibition-module"
    :style="{
      width: width,
      height: height
    }"
  >
    <ofa-swiper
      class="swiper-module"
      :has-pagination="hasPagination"
      :data-source="dataSource"
      v-bind="$attrs"
    >
      <template #default="{ data }">
        <ofa-image
          width="100%"
          height="100%"
          :src="data[compatibleProps.image]"
          @click="handleClick(data)"
        />
      </template>
    </ofa-swiper>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { prefix } from '@business/utils/index.js'
import swiper from '../roll'
import Image from '../image'

export default {
  name: `${prefix}roll-exhibition`,
  components: {
    'ofa-swiper': swiper,
    [Image.name]: Image
  },
  props: {
    dataSource: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: '700px'
    },
    height: {
      type: String,
      default: '349px'
    },
    hasPagination: {
      type: Boolean,
      default: true
    },
    // 兼容属性
    compatibleProps: {
      type: Object,
      default: () => {
        return {
          image: 'image',
          id: 'score'
        }
      }
    }
  },
  emits: ['selectImage'],
  setup(_, { emit }) {
    onMounted(() => {})

    const handleClick = (val) => {
      emit('selectImage', val)
    }
    return {
      handleClick
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-module,
.image {
  height: 100% !important;
  width: 100% !important;
}
:deep(.swiper-container) {
  height: 100% !important;
  .swiper-wrapper {
    height: 100% !important;
  }
}
:deep(.swiper-pagination) {
  text-align: end;
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 13px 0 0;
    background: #ffae87;
  }
  .swiper-pagination-bullet-active {
    background: #e9521e;
  }
}
</style>
