import { h } from 'vue'
export default [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: Login */ '../views/Login.vue')
  },
  {
    path: '/devOnline/RenderView',
    name: 'renderView',
    meta: {
      title: '配置预览'
    },
    component: () =>
      import(
        /* webpackChunkName: develop */ '../views/devOnline/develop/RenderView.vue'
      )
  },
  {
    path: '/devOnline/renderComponent',
    name: 'renderComponent',
    meta: {
      title: '配置组件'
    },
    component: () =>
      import(
        /* webpackChunkName: develop */ '../views/devOnline/develop/RenderComponent.vue'
      )
  },
  {
    path: '/devOnline/RenderPreview',
    name: 'RenderPreview',
    meta: {
      title: '预览'
    },
    component: () =>
      import(
        /* webpackChunkName: develop */ '../views/devOnline/develop/RenderPreview.vue'
      )
  },
  {
    path: '/devOnline/RenderComponentPreview',
    name: 'RenderComponentPreview',
    meta: {
      title: '预览'
    },
    component: () =>
      import(
        /* webpackChunkName: develop */ '../views/devOnline/develop/RenderComponentPreview.vue'
      )
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404页面'
    },
    component: h('span', null, 404)
  }
]
