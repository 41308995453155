<template>
  <div class="reader-consult-module">
    <a-form
      ref="formRef"
      name="custom-validation"
      :model="formState"
      :rules="rules"
      v-bind="layout"
      :label-col="labelCol"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item class="input-area" name="desc">
        <span class="lable required">咨询内容</span>
        <a-textarea v-model:value="formState.desc" :placeholder="placeholder" />
      </a-form-item>
      <a-form-item name="phone">
        <span class="lable required">联系电话</span>
        <a-input v-model:value="formState.phone" />
      </a-form-item>
      <a-form-item name="name">
        <span class="lable required">姓名</span>
        <a-input v-model:value="formState.name" />
      </a-form-item>
      <a-form-item label="性别">
        <a-radio-group v-model:value="sex" @change="sexChange">
          <a-radio :value="1">男</a-radio>
          <a-radio :value="2">女</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item name="email">
        <span class="lable">邮箱地址</span>
        <a-input v-model:value="formState.email" />
      </a-form-item>
      <a-form-item name="code" class="code-input">
        <span class="lable required">验证码</span>
        <div class="codeContainer">
          <a-input v-model:value="formState.code" />
          <ofa-image
            :width="90"
            :height="32"
            :preview="false"
            :src="state._codeImage"
            @click="changeCode"
          ></ofa-image>
        </div>
      </a-form-item>
      <a-form-item class="this-button" :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" html-type="submit" @click="onSubmit"
          >提交咨询</a-button
        >
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { prefix } from '@business/utils/index.js'
import { reactive, toRaw, ref, watch } from 'vue'
import Image from '../image'
import {
  Form as AForm,
  Input as AInput,
  Button as AButton,
  Radio as ARadio
} from 'ant-design-vue'

const ARadioGroup = ARadio.Group
const AFormItem = AForm.Item
const ATextarea = AInput.TextArea

export default {
  name: `${prefix}reader-consult`,
  components: {
    [Image.name]: Image,
    ARadio,
    ARadioGroup,
    AForm,
    AFormItem,
    AInput,
    AButton,
    ATextarea
  },
  props: {
    resForm: {
      type: Object,
      default: () => {}
    },
    codeImage: {
      type: String,
      default: ''
    },
    maxWords: {
      type: Number
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['submit', 'changeCode', 'update:resForm'],
  setup(props, { emit }) {
    const sex = ref(1)
    const formRef = ref()
    const formState = reactive({
      desc: '',
      email: '',
      phone: '',
      name: '',
      sex: 1,
      code: ''
    })
    const state = reactive({
      _codeImage: props.codeImage
    })
    // "^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\\d{8}$"
    let validatePhone = async (rule, value) => {
      if (value === '') {
        return Promise.reject('请输入联系电话')
      } else if (!/^1[3|4|5|7|8]\d{9}$/.test(value)) {
        return Promise.reject('格式错误,请重新输入')
      } else {
        return Promise.resolve()
      }
    }

    let validateMessage = async (rule, value) => {
      if (value === '') {
        return Promise.reject('请输入咨询内容')
      } else if (value.length > props.maxWords) {
        return Promise.reject('咨询内容字数超出限制')
      } else {
        return Promise.resolve()
      }
    }

    let validateEmail = async (rule, value) => {
      if (
        value !== '' &&
        !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+$/.test(value)
      ) {
        return Promise.reject('格式错误,请重新输入')
      } else {
        return Promise.resolve()
      }
    }

    const rules = {
      desc: [
        {
          required: true,
          validator: validateMessage,
          trigger: 'blur'
        }
      ],
      phone: [
        {
          required: true,
          validator: validatePhone,
          trigger: 'blur'
        }
      ],
      name: [
        {
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }
      ],
      email: [
        {
          required: true,
          validator: validateEmail,
          trigger: 'blur'
        }
      ],
      code: [
        {
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }
      ]
    }
    const layout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }

    const handleFinish = (values) => {
      console.log(values, formState, '成功')
    }

    const handleFinishFailed = (errors) => {
      console.log(errors, '失败')
    }

    watch(
      () => props.codeImage,
      (val) => {
        state._codeImage = val
      }
    )

    //单选框的value改变触发事件
    const sexChange = (e) => {
      formState.sex = e.target.value
    }

    const changeCode = () => {
      emit('changeCode')
    }

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log(toRaw(formState), ' toRaw(formState)')
          console.log('formState', formState)
          emit('update:resForm', formState)
          emit('submit', formState)
        })
        .catch((error) => {
          console.log('error', error)
        })
    }

    return {
      changeCode,
      sex,
      state,
      formState,
      formRef,
      rules,
      layout,
      onSubmit,
      handleFinishFailed,
      handleFinish,
      sexChange
    }
  }
}
</script>
<style scoped lang="scss">
.reader-consult-module {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  // background-color: #ceeeee;
  // width: 300px;
  // height: 565px;
  // padding-top: 80px;
}
.required {
  padding-left: 10px;
  &::after {
    position: absolute;
    left: 0;
    top: -10px;
    content: '*';
    color: red;
  }
}
.codeContainer {
  position: relative;
}
:deep(.ant-form-item) {
  margin-bottom: 10px;
  position: relative;
  // border: 1px solid;
}
:deep(.ant-col-14) {
  width: 100%;
  max-width: 100%;
  flex: 1;
}
.ant-input {
  height: 32px;
  // background-color: #000;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.lable {
  display: flex;
  position: relative;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  align-items: center;
  height: 20px;
  // background-color: #00f;
}
.code-input {
  position: relative;
}
.code-input .ant-input {
  max-width: 140px;
}
:deep(.ant-image) {
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  right: 0;
}
.ant-btn-primary {
  width: 100px;
  height: 36px;
  background: #c5703d;
  border-radius: 4px;
}
.input-area .ant-input {
  height: 60px;
  padding-right: 10px;
}
:deep(.ant-form-explain) {
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: -17px;
  right: 10px;
}
:deep(.ant-col-offset-4) {
  margin: 0;
}
:deep(.this-button) {
  .ant-form-item-control {
    text-align: center;
  }
}
:deep(.ant-btn-primary) {
  border-color: transparent;
}
:deep(.ant-radio-checked .ant-radio-inner) {
  border-color: #666;
}
:deep(.ant-radio-inner::after) {
  background-color: #666;
}
</style>
